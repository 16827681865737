import React, { Component } from 'react';
import _get from 'lodash/get';
import Layout from 'components/layout'
import AsicHeader from 'components/asic/AsicHeader';
import Community from 'components/asic/Community';
import AsicContainer from 'components/asic/AsicContainer';
import WL from 'constants/whiteLabel';
import MODELS from 'constants/asicModels';

class Asic extends Component {
  render () {
    return (
      <Layout page="asic" withHeader={false} lang={_get(this.props, 'pageContext.lang', 'en')}>
        <AsicHeader />
        <AsicContainer selected={MODELS.S17}/>
        {WL.hasCommunity() ? <Community /> : null}
      </Layout>
    );
  }
}

export default Asic;
